@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");

//  bg area
.bg-area {
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  // title
  .title {
    font-family: "Montserrat", sans-serif;
    font-size: 10vw;

    background: linear-gradient(
      135deg,
      #fa8bff 0%,
      #2bd2ff 25%,
      #2bff88 50%,
      #2bd2ff 75%,
      #fa8bff 100%
    );

    background-size: 400%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: animate 10s linear infinite;
  }

  @keyframes animate {
    to {
      background-position: 400%;
    }
  }

  // title
  .logo {
    width: 10vw;
    position: absolute;
    top: 93.8vh;

    /*如果螢幕寬度為 768px 以下，就套用 css 設定*/
    @media screen and (max-width: 768px) {
      top: 90vh;
    }
  }
}
