@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-area{width:100vw;height:100vh;background-color:#fafafa;display:flex;flex-direction:column;justify-content:center;align-items:center;overflow:hidden}.bg-area .title{font-family:"Montserrat", sans-serif;font-size:10vw;background:linear-gradient(135deg, #fa8bff 0%, #2bd2ff 25%, #2bff88 50%, #2bd2ff 75%, #fa8bff 100%);background-size:400%;-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:transparent;-webkit-animation:animate 10s linear infinite;animation:animate 10s linear infinite}@-webkit-keyframes animate{to{background-position:400%}}@keyframes animate{to{background-position:400%}}.bg-area .logo{width:10vw;position:absolute;top:93.8vh}@media screen and (max-width: 768px){.bg-area .logo{top:90vh}}

